"use client";

import { Fragment } from "react";
import { usePathname } from "next/navigation";
import { BreadcrumbJsonLd } from "next-seo";
import { ROOT_URL } from "@/utils/paths-utils";
import { Link } from "../base/link";
import { ChevronRightIcon } from "../icons/icons";

interface BreadcrumbsProps {
  /**
   * Breadcrumb override
   */
  breadcrumbs?: string | null;
  hideBreadcrumbs?: boolean;
}

export const Breadcrumbs = ({ breadcrumbs, hideBreadcrumbs }: BreadcrumbsProps) => {
  const pathname = usePathname();

  if (hideBreadcrumbs) {
    return <></>;
  }

  const separator = <ChevronRightIcon size="x-small" color="gray" />;

  const pathParts = pathname.split("/").filter((path) => path);

  if (pathParts.length <= 1) {
    return <></>;
  }

  // override with breadcrumb from Strapi
  const breadcrumbParts = breadcrumbs && breadcrumbs.split("/").filter((path) => path);

  return (
    <div className="breadcrumbs">
      <BreadcrumbJsonLd
        useAppDir={true}
        itemListElements={[
          { item: `${ROOT_URL}`, name: "Hjem", position: 1 },
          ...pathParts.map((pathPart, index) => {
            return {
              item: `${ROOT_URL}/${pathParts.slice(0, index + 1).join("/")}`,
              name: pathPart,
              position: index + 2
            };
          })
        ]}
      />

      {pathParts.map((item, index) => {
        const href = `/${pathParts.slice(0, index + 1).join("/")}`;

        let displayItem = item;

        // replace '3t' with '3T'
        if (displayItem.indexOf("3t") > 0) {
          displayItem = displayItem.replaceAll("3t", "3T");
        }

        // replace '-' (dash) with ' ' (space)
        if (displayItem.indexOf("-") > 0) {
          displayItem = displayItem.replaceAll("-", " ");
        }

        // replace '_' (underscore) with ' ' (space)
        if (displayItem.indexOf("_") > 0) {
          displayItem = displayItem.replaceAll("_", " ");
        }

        // override with breadcrumb part from Strapi, if there is one
        // if this specific part of breadcrumb is missing, then use part from url
        if (breadcrumbParts && breadcrumbParts[index]) {
          displayItem = breadcrumbParts[index];
        }

        // capitalize links either way
        displayItem = displayItem[0].toUpperCase() + displayItem.slice(1, displayItem.length);

        return (
          <Fragment key={index}>
            {index < pathParts.length - 1 ? (
              <Link href={href}>{displayItem}</Link>
            ) : (
              <span className="breadcrumbs__current">{displayItem}</span>
            )}
            {pathParts.length !== index + 1 && separator}
          </Fragment>
        );
      })}
    </div>
  );
};
